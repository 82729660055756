// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { MetaDataSchema } from '@/types';

export const ComplianceCheck = z.object({
  id: z.string(),
  name: z.string(),
  required: z.boolean(),
  description: z.string(),
});

export type ComplianceCheckType = z.infer<typeof ComplianceCheck>;

export const CompliancesCheckSchema = z.object({
  data: z.array(ComplianceCheck),
  meta: MetaDataSchema,
});

export type ComplianceChecks = z.infer<typeof CompliancesCheckSchema>;

export async function fetchComplianceChecks() {
  const response = await fetch(`${apiSharedUrl}/lists/compliance`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = CompliancesCheckSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useComplianceChecksQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'compliance-checks'],
    queryFn: fetchComplianceChecks,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });

  return {
    ...queryFns,
    complianceChecks: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
