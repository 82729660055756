// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { MetaDataSchema } from '@/types';

export const CountrySchema = z.object({
  name: z.string().optional(),
  iso_code: z.string().optional(),
  currency: z.string().optional(),
  symbol: z.string().optional(),
  timezone: z.string().optional(),
  telephone_code: z.string().optional(),
  flag: z.string().optional(),
});

export const GoogleLocationSchema = z.union([
  z.any(),
  z.object({
    data: z.array(z.union([z.any(), z.object({ description: z.string(), place_id: z.string() })])),
  }),
]);

export const CountriesSchema = z.object({
  data: z.array(CountrySchema),
  meta: MetaDataSchema,
});

export type Country = z.infer<typeof CountrySchema>;

export type Countries = z.infer<typeof CountriesSchema>;

export type GoogleLocations = z.infer<typeof GoogleLocationSchema>;

/**
 * Fetches a list of countries from the API.
 * @returns An array of countries and metadata.
 * @throws {Error} If there is an error parsing the response or if the API request fails.
 */
export async function fetchCountries() {
  const response = await fetch(
    `${apiSharedUrl}/lists/countries?limit_keys=name,iso_code,currency,symbol,flag`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  );

  const data = await response.json();

  const result = CountriesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

/**
 * Custom hook for querying countries.
 * @returns An object containing the query functions, countries data, and metadata.
 */
export const useCountriesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'countries'],
    queryFn: fetchCountries,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });

  return {
    ...queryFns,
    countries: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
