import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { MetaDataSchema } from '@/types';

export const CompanyValueSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const CompanyValuesSchema = z.object({
  data: z.array(CompanyValueSchema),
  meta: MetaDataSchema,
});

export type CompanyValues = z.infer<typeof CompanyValuesSchema>;

export async function fetchCompanyValues() {
  const response = await fetch(`${apiSharedUrl}/lists/company-values`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = CompanyValuesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useCompanyValuesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'company-values'],
    queryFn: fetchCompanyValues,
    staleTime: 1000 * 60 * 60 * 20,
  });

  return {
    ...queryFns,
    companyValues: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
