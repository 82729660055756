import Swal, { SweetAlertOptions, SweetAlertPosition } from 'sweetalert2';

/* eslint-disable max-len */
const COLORS = {
  white: '#ffffff',
  primary: '#0B6CF4',
  primaryMid: '#084C9A',
  primaryDarker: '#080f5b',
  error: '#ff5566',
  success: '#80e6a0',
  rating: '#ffa700',
};

class Notify {
  // constructor() {
  //   this.notify = Notify.notify?.bind(this);
  // }

  static async notify(
    message: string,
    title?: string,
    icon?: string,
    color: string = COLORS.primary,
    position?: SweetAlertPosition,
    timer?: number,
    toast = true
  ) {
    const options: SweetAlertOptions = {
      title,
      text: message || 'Action was successful',
      confirmButtonColor: color,
      position: position || 'top-right',
      toast,
      showCloseButton: true,
      showConfirmButton: false,
      iconHtml:
        icon ||
        '<svg class="h-6 w-6 text-blue-400" x-description="Heroicon name: outline/information-circle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path></svg>',
      customClass: {
        icon: 'no-border',
        container: 'swal-container',
      },
      // closeButtonColor: color,
      timer: timer || 5000,
    };

    return Swal?.fire(options);
  }

  static error(
    message: string,
    title = 'Something went wrong',
    position?: SweetAlertPosition,
    timer?: number
  ) {
    const icon = `<div class="bg-red-100 rounded-full p-1"><svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class="feather feather-alert-triangle h-5 w-5 text-red-400  ">
  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
  <line x1="12" y1="9" x2="12" y2="13"></line>
  <line x1="12" y1="17" x2="12.01" y2="17"></line>
</svg>
</div>`;
    //       '<svg class="h-6 w-6 text-red-400" x-description="Heroicon name: outline/x-circle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';

    return Notify.notify(message, title, icon, COLORS.error, position, timer);
  }

  static warn(
    message: string,
    title = 'Something went wrong',
    position?: SweetAlertPosition,
    timer?: number
  ) {
    const icon = `<div class="bg-red-100 rounded-full p-1"><svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class="feather feather-alert-triangle h-5 w-5 text-orange-400  ">
  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
  <line x1="12" y1="9" x2="12" y2="13"></line>
  <line x1="12" y1="17" x2="12.01" y2="17"></line>
</svg>
</div>`;
    //       '<svg class="h-6 w-6 text-red-400" x-description="Heroicon name: outline/x-circle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';

    return Notify.notify(message, title, icon, COLORS.error, position, timer);
  }

  static success(
    message?: string,
    title = 'Success',
    position?: SweetAlertPosition,
    timer?: number
  ) {
    const icon = `<div class="bg-green-100 rounded-full p-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
     class="feather feather-check-circle h-5 w-5 text-green-400  ">
     <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></div>`;
    // '<svg class="h-6 w-6 text-green-400" x-description="Heroicon name: outline/check-circle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';

    return Notify.notify(message || '', title, icon, COLORS.success, position, timer);
  }
}

export default Notify;
