'use client';
/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Briefcase, Navigation, UserPlus, Users } from 'react-feather';
import useSWR from 'swr';
import { apiUrl } from '@/api.config';
import { MeetingDetails } from '@/components/Modals/interviews/types';
import Get from '@/utils/Get';
import { CandidateHeaderData } from '@/modules/Jobs/types/candidate';
import { DashboardUpcomingInterviewRes } from '@/modules/Jobs/types/dashboardUpcomingInterviewRes';
import { RecentResponsesRes } from '@/modules/Jobs/types/RecentResponsesRes';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectAuthState } from '@/store/selectors';
import { setPreviewingApplicationId } from '@/store/slices';
import { setPreviewJobId } from '@/store/slices/applications';
import { formatDateInTimezone } from '@/utils/date.helper';

type QuickUpdateData = { title: string; applied_count_today: number; uid: string };
type RecentResponseData = {
  talentAvatar: string;
  jobTitle: string;
  talentFullName: string;
  matchScore: number;
  applicationId: string;
  talentId: string;
  location: string;
  dateApplied: string;
  jobId: string;
};

export default function useDashboard() {
  // const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuthState);
  const { data: metricsData, error: metricError } = useSWR(
    user?.token ? `${apiUrl}/dashboard/count-metrics` : null,
    (_url: string) => Get(_url, undefined, user?.token)
  );
  const {
    data: upcomingInterviewData,
    error: upcomingInterviewError,
    mutate: refreshUpcomingInterview,
  } = useSWR<DashboardUpcomingInterviewRes>(
    user?.token ? `${apiUrl}/dashboard/upcoming-interview` : null,
    (_url: string) => Get(_url, undefined, user?.token)
  );
  const { data: quickUpdatesData, error: quickUpdatesError } = useSWR(
    user?.token ? `${apiUrl}/daily-summary/applications` : null,
    (_url: string) => Get(_url, undefined, user?.token)
  );
  const { data: recentResponsesData, error: recentResponsesError } = useSWR<RecentResponsesRes>(
    user?.token ? `${apiUrl}/dashboard/new-response` : null,
    (_url: string) => Get(_url, undefined, user?.token)
  );

  const dispatch = useAppDispatch();
  const [showMeetingDetailsModal, setShowMeetingDetailsModal] = useState(false);
  const [showCandidateViewModal, setShowCandidateViewModal] = useState(false);
  const [currentCandidateView, setCurrentCandidateView] = useState<CandidateHeaderData>();
  const isLoadingUpcomingInterview = !upcomingInterviewData && !upcomingInterviewError;
  const isLoadingQuickUpdates = !quickUpdatesData && !quickUpdatesError;
  const isLoadingRecentResponses = !recentResponsesData && !recentResponsesError;
  const isLoadingMetrics = !metricsData && !metricError;

  const dashboardItems = useMemo(() => {
    const { live_jobs, total_application, hired_talents, team_members } = (metricsData?.data ||
      {}) as any;

    return [
      {
        title: 'Live Jobs',
        Icon: Briefcase,
        count: live_jobs || 0,
        color: 'gray',
      },
      {
        title: 'Total Applications',
        Icon: Navigation,
        count: total_application || 0,
        color: 'red',
      },
      {
        title: 'Team Members',
        Icon: Users,
        count: team_members || 0,
        color: 'green',
      },
      {
        title: 'Talents Hired',
        Icon: UserPlus,
        count: hired_talents || 0,
        color: 'blue',
      },
    ];
  }, [metricsData?.data]);

  const upcomingInterview = useMemo(() => {
    const { id } = upcomingInterviewData?.data || {};
    const { meeting, job_application_id } = upcomingInterviewData?.data?.attributes || {};
    const { talent, job } = upcomingInterviewData?.data?.included || {};
    const { avatar, first_name, last_name } = talent?.attributes || {};
    const { title } = job?.attributes || {};

    const startDateInUserTimezone = formatDateInTimezone(meeting?.starts_at || '', user?.timezone);

    const endDateInUserTimezone = formatDateInTimezone(meeting?.ends_at || '', user?.timezone);

    const meetingDetails: MeetingDetails = {
      candidateId: talent?.id || 'N/A',
      interviewId: id || 'N/A',
      jobApplicationId: job_application_id || 'N/A',
      avatars: [user?.avatar || '', avatar || ''],
      title: meeting?.title || 'N/A',
      date: startDateInUserTimezone,
      duration: meeting?.duration || 'N/A',
      time: `${startDateInUserTimezone} to ${endDateInUserTimezone}`, // `${convertTime('09:00')} to ${convertTime('12:00')}`,
      startDate: new Date(startDateInUserTimezone),
      endDate: new Date(endDateInUserTimezone),

      link: meeting?.meeting_link || '-',
    };

    return upcomingInterviewData?.data && first_name && last_name
      ? {
          jobId: job?.id,
          jobTitle: title || '-',
          talentFullName: [first_name ?? '-', last_name ?? '-'].join(' '),
          talentAvatar: avatar || '',
          meeting: meetingDetails,
        }
      : null;
  }, [upcomingInterviewData?.data, user]);

  const quickUpdates = useMemo(
    () => [
      ...(quickUpdatesData?.data?.map((qu: QuickUpdateData) => ({
        title: qu.title,
        summary: `Daily summary: you have ${qu.applied_count_today} new applications for this role`,
        link: `/jobs/${qu.uid || ''}/applications`,
      })) || []),
      // {
      //   title: 'Applications',
      //   summary: `Daily summary: you have 10 applications for 5 roles`,
      //   link: '',
      // },
      // {
      //   title: 'Shortlists',
      //   summary: `Daily summary: you have 10 new shortlists for 5 roles`,
      //   link: '',
      // },
      // {
      //   title: 'Reschedule Requests',
      //   summary: `Daily summary: you have 10 applications for 5 roles`,
      //   link: '',
      // },
      // {
      //   title: 'Messages',
      //   summary: `Daily summary: you have 10 applications for 5 roles`,
      //   link: '/messages',
      // },
    ],
    [quickUpdatesData?.data]
  );

  const recentResponses = useMemo(
    () => [
      ...(recentResponsesData?.data?.map((rr) => {
        const {
          job_title,
          job_uid,
          talent_first_name,
          talent_last_name,
          avatar,
          talent_uid,
          match_score,
          // application_id,
          application_uid,
        } = rr || {};
        const recentRes: RecentResponseData = {
          talentAvatar: avatar,
          talentFullName: [talent_first_name ?? 'N/A', talent_last_name ?? 'N/A'].join(' '),
          jobTitle: job_title,
          matchScore: match_score || 0,
          talentId: talent_uid,
          applicationId: application_uid,
          jobId: job_uid,
          location: '',
          dateApplied: '',
        };
        return recentRes;
      }) || []),
    ],
    [recentResponsesData?.data]
  );

  const handleViewUpcomingInterview = useCallback(
    (interview: typeof upcomingInterview) => {
      if (interview?.jobId) {
        dispatch(setPreviewJobId(interview?.jobId));
        setShowMeetingDetailsModal(true);
      }
    },
    [dispatch]
  );

  const handleViewRecentResponse = useCallback(
    async (recentRes: RecentResponseData) => {
      const candidate: CandidateHeaderData = {
        avatarUrl: recentRes.talentAvatar,
        candidateId: recentRes.talentId,
        candidateName: recentRes.talentFullName,
        dateApplied: '',
        location: '',
      };

      dispatch(setPreviewingApplicationId(recentRes.applicationId));
      dispatch(setPreviewJobId(recentRes.jobId));
      setCurrentCandidateView(candidate);
      setShowCandidateViewModal(true);
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(setPreviewJobId(''));
    };
  }, [dispatch]);

  const hookReturn = {
    user,
    dashboardItems,
    quickUpdates,
    recentResponses,
    showMeetingDetailsModal,
    setShowMeetingDetailsModal,
    showCandidateViewModal,
    setShowCandidateViewModal,
    currentCandidateView,
    handleViewRecentResponse,
    handleViewUpcomingInterview,
    isLoadingQuickUpdates,
    isLoadingUpcomingInterview,
    isLoadingRecentResponses,
    isLoadingMetrics,
    upcomingInterview,
    refreshUpcomingInterview,
  };

  return hookReturn;
}
