// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { LabelValueSchema, MetaDataSchema } from '@/types';

export const GendersSchema = z.object({
  data: z.array(LabelValueSchema),
  meta: MetaDataSchema,
});

export type GendersType = z.infer<typeof GendersSchema>;

export async function fetchGenders() {
  const response = await fetch(`${apiSharedUrl}/lists/genders`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = GendersSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useGendersQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'genders'],
    queryFn: fetchGenders,
    staleTime: 1000 * 60 * 30, // 30 minutes
  });

  return {
    ...queryFns,
    genders: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
