'use client';

import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode, useRef } from 'react';
type DialogErrorProps = {
  isInfo?: boolean;
  title?: string;
  description?: string;
  confirm?: {
    text: string;
    onConfirm: () => void;
  };
  cancel?: {
    text: string;
    onCancel: () => void;
  };
  onClose: () => void;
  open: boolean;
  children?: ReactNode;
};
export default function DialogError({
  title,
  description,
  confirm,
  cancel,
  onClose,
  isInfo,
  open,
  children
}: DialogErrorProps) {
  const cancelButtonRef = useRef(null);
  const Icon = isInfo ? InformationCircleIcon : ExclamationTriangleIcon;
  return <Transition.Root show={open} as={Fragment} data-sentry-element="unknown" data-sentry-component="DialogError" data-sentry-source-file="DialogError.tsx">
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="DialogError.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="DialogError.tsx">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="scrollbar-primary fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="DialogError.tsx">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6" data-sentry-element="unknown" data-sentry-source-file="DialogError.tsx">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Icon className="h-6 w-6 text-red-600" aria-hidden="true" data-sentry-element="Icon" data-sentry-source-file="DialogError.tsx" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    {title && <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>}
                    {description && <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>}
                    {children}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {confirm && <button type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => confirm?.onConfirm()}>
                      {confirm?.text}
                    </button>}
                  {cancel && <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => cancel.onCancel()} ref={cancelButtonRef}>
                      {cancel.text}
                    </button>}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
}