import { z } from 'zod';
import { ScreenError } from '@/constants/error/types';

export const LabelValueSchema = z.object({
  label: z.string().or(z.number()),
  value: z.string().or(z.number()),
});

export type LabelValue = z.infer<typeof LabelValueSchema>;

export const MetaDataSchema = z.object({
  status: z.string(),
  message: z.string(),
});

export const LabelValueOption = z.record(LabelValueSchema);
export type LabelValueOptionType = z.infer<typeof LabelValueOption>;

export type APIMetaData = z.infer<typeof MetaDataSchema>;

export type BasicOptionData = {
  data: { label: string; value: string }[];
  meta: APIMetaData;
};

export const LabelValuesSchema = z.object({
  data: z.array(LabelValueSchema),
  meta: MetaDataSchema,
});

export type LabelValues = z.infer<typeof LabelValuesSchema>;

export const ArrayOfStringSchema = z.array(z.string()).optional();

export type ArrayOfString = z.infer<typeof ArrayOfStringSchema>;

export type APIResponse<T> = {
  data: T;
  meta: APIMetaData;
};

export type ResponseError = {
  status?: 'error';
  message: string | undefined;
  errors?: Record<string, Array<string>>;
  error?: ScreenError;
};

export type ResponseData = {
  status?: 'success';
  title: string | undefined;
  [x: string]: any;
  data?: { [x: string]: any } & Array<unknown>;
  meta?: { status: string; message: string } & Record<string, unknown>;
  included: Record<string, unknown> | Array<unknown>;
};

export type PROCESS_ENV =
  | NodeJS.ProcessEnv
  | {
      API_URL: string;
      API_SHARED_URL: string;
    };

export const DateRangeSchema = z.object({
  start_date: z
    .object({
      year: z.number().nullable(),
      month: z.number().nullable(),
    })
    .nullable(),
  end_date: z
    .object({
      year: z.number().nullable(),
      month: z.number().nullable(),
    })
    .nullable()
    .optional(),
});

export type TDateRange = z.infer<typeof DateRangeSchema>;

export type DataOrErrorResponse = {
  status?: 'success';
  [x: string]: any;
  data?: { [x: string]: any } | Array<unknown> | null;
  meta?: { status: string; message: string; error?: string };
  included?: Record<string, unknown> | Array<unknown>;
};
