// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { LabelValueSchema, MetaDataSchema } from '@/types';

export const fundingStagesSchema = z.object({
  data: z.array(LabelValueSchema),
  meta: MetaDataSchema,
});

export type FundingStages = z.infer<typeof fundingStagesSchema>;

export async function fetchFundingStages() {
  const response = await fetch(`${apiSharedUrl}/lists/funding-stages`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = fundingStagesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useFundingStagesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'funding-stages'],
    queryFn: fetchFundingStages,
    staleTime: 1000 * 60 * 20, // 20 minutes
  });

  return {
    ...queryFns,
    fundingStages: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
