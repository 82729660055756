'use client';

/* eslint-disable max-lines */
import classNames from 'classnames';
import React from 'react';
import { ChevronRight } from 'react-feather';
import MeetingDetailsModal from '@/components/Modals/interviews/MeetingDetailsModal';
import CandidateViewModal from '@/components/Modals/jobs/CandidateViewModal';
import { AvatarPrimary, LoaderSpinner } from '@/components/UI';
import EmptyState from '@/ui/EmptyState';
import useDashboard from '../hooks/useDashboard';
import Link from 'next/link';
import nerdPerson from '@/public/assets/images/dashboard/nerd-person.svg';
import sadBox from '@/public/assets/images/dashboard/sad-box.svg';
import sadMail from '@/public/assets/images/dashboard/sad-mail.svg';
/* import { Card, CardContent, CardHeader, CardTitle } from '@/ui/card';
import { DollarSign, Users, CreditCard, Activity } from 'react-feather'; */

import userImage from '@/public/assets/images/user-image.png';
export default function DashboardContainer() {
  const dashboardHook = useDashboard();
  const {
    dashboardItems,
    quickUpdates,
    recentResponses,
    isLoadingQuickUpdates
  } = dashboardHook;
  const upcomingMeetingDetail = dashboardHook?.upcomingInterview?.meeting;
  return <>
      <div className="p-5 sm:p-8">
        {/* <div className="flex justify-end">
          <button
            type="button"
            className="flex items-center justify-center bg-white border
           rounded-md p-2  text-sm md:text-base drop-shadow-sm
           text-gray-600 leading-tight tracking-[1px]">
            <Bell size={18} />
            <span className="ml-2">Notifications</span>
          </button>
         </div> */}

        <div className="mt-6">
          <h1 className="text-[24px] font-bold leading-[38px] text-gray-900 md:text-[30px]">
            Welcome Back, {dashboardHook.user?.first_name || 'Dear'}
          </h1>
          <p className="mt-2 text-[16px] font-normal leading-6 text-gray-500">
            Here’s a quick summary of important details to note
          </p>
        </div>

        {/* <div className="mt-6 grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Total Revenue</CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">$45,231.89</div>
              <p className="text-xs text-muted-foreground">+20.1% from last month</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Subscriptions</CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">+2350</div>
              <p className="text-xs text-muted-foreground">+180.1% from last month</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Sales</CardTitle>
              <CreditCard className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">+12,234</div>
              <p className="text-xs text-muted-foreground">+19% from last month</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Active Now</CardTitle>
              <Activity className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">+573</div>
              <p className="text-xs text-muted-foreground">+201 since last hour</p>
            </CardContent>
          </Card>
         </div> */}

        <div className="mt-6 grid gap-x-12 gap-y-8 xl:grid-cols-3">
          <div className="grid grid-rows-1 gap-8 sm:grid-cols-2 xl:col-span-2">
            {dashboardItems.map(({
            title,
            Icon,
            count,
            color
          }, i) => {
            return <div key={JSON.stringify(title + i)} className="flex flex-wrap items-center gap-y-2 border bg-white p-6">
                  <div className={`flex h-20 w-20 items-center justify-center`}>
                    <span className={classNames(`flex bg-${color}-50 dashboard-icon h-20 w-20 items-center justify-center rounded-full text-center text-2xl`, `bg-${color}-100 text-${color}-500`)}>
                      <Icon size={32} />
                    </span>
                  </div>
                  <div className="ml-3 flex flex-1 flex-col gap-y-[10px] text-ellipsis">
                    <p className="line-clamp-2 text-sm font-medium text-gray-500">{title}</p>
                    <p className="mt-1 text-[24px] font-bold text-black">{count}</p>
                  </div>
                </div>;
          })}
          </div>

          {/* Upcoming */}
          <div className="flex flex-col border bg-white">
            <div className="border-b p-4 px-6">
              <p className="text-lg font-semibold text-gray-700">Upcoming Interview</p>
            </div>

            <div className="mb-12 flex flex-grow items-start justify-between border-b p-6">
              {dashboardHook.isLoadingUpcomingInterview && <div className="mx-auto self-center">
                  <LoaderSpinner className="h-4 w-4" />
                </div>}
              {Boolean(!dashboardHook.isLoadingUpcomingInterview && !dashboardHook.upcomingInterview) && <EmptyState src={nerdPerson} description="No upcoming Interview. You’ll be notified here when this changes" />}
              {!dashboardHook.isLoadingUpcomingInterview && dashboardHook.upcomingInterview && <div className="w-full">
                  <p className="text-center text-sm font-medium text-gray-500">
                    {upcomingMeetingDetail?.startDate?.toLocaleTimeString(undefined, {
                  timeStyle: 'short'
                })}
                    , {upcomingMeetingDetail?.startDate?.toDateString()}
                  </p>
                  <div className="mt-4 flex w-full items-center gap-x-5">
                    <div className="">
                      <AvatarPrimary src={dashboardHook.upcomingInterview.talentAvatar || userImage} size={60} hideIndicators />
                    </div>

                    <div className="">
                      <p className="line-clamp-2 text-sm font-medium text-gray-900">
                        {dashboardHook.upcomingInterview.jobTitle}
                      </p>
                      <p className="text-sm font-normal text-gray-600">
                        {dashboardHook.upcomingInterview.talentFullName}
                      </p>
                    </div>
                    <button type="button" className="ml-auto text-sm font-normal text-gray-500" onClick={() => dashboardHook.handleViewUpcomingInterview(dashboardHook.upcomingInterview)}>
                      View
                    </button>
                  </div>
                </div>}
            </div>
          </div>
        </div>

        <div className="mt-6 grid gap-x-12 gap-y-6 md:gap-y-6 xl:grid-cols-3">
          {/* Quick updates */}
          <div className="flex flex-col rounded-md border bg-white drop-shadow-sm xl:col-span-2">
            <div className="border-b p-4 px-6">
              <span className="text-lg font-semibold leading-6 text-gray-800">Quick Updates</span>
            </div>
            <div className="flex min-h-[300px] flex-col">
              {dashboardHook.isLoadingQuickUpdates && <div className="mx-auto my-auto flex flex-col self-center">
                  <LoaderSpinner className="h-4 w-4" />
                </div>}
              {Boolean(!isLoadingQuickUpdates && !quickUpdates.length) && <div className="my-auto flex max-h-80 flex-grow p-6">
                  <EmptyState src={sadMail} title="No Recent Updates!" description="We’ll let you know as soon as there’s an update" />
                </div>}
              {Boolean(!isLoadingQuickUpdates && quickUpdates.length) && <div className="flex flex-col">
                  {quickUpdates.map((update, i) => {
                return <div className="flex items-center justify-between border-b px-8 py-6" key={JSON.stringify(update.title + i)}>
                        <div>
                          <p className="text-sm font-medium text-black">{update.title}</p>
                          <p className="text-sm font-normal text-gray-600">{update.summary}</p>
                        </div>
                        {/* active only wen update summary count is not empty */}
                        <Link href={update.link || '#'} className="rounded-full border p-2 text-gray-500">
                          <ChevronRight size={18} className="" />
                        </Link>
                      </div>;
              })}
                </div>}
            </div>
          </div>

          {/* Recent Responses */}
          <div className="flex flex-col rounded-md border bg-white drop-shadow-sm">
            <div className="border-b p-4 px-6">
              <span className="text-lg font-semibold leading-6 text-gray-800">
                Recent Responses
              </span>
            </div>
            <div className="flex min-h-[300px] flex-col">
              {dashboardHook.isLoadingRecentResponses && <div className="mx-auto my-auto flex flex-col self-center">
                  <LoaderSpinner className="h-4 w-4" />
                </div>}
              {Boolean(!dashboardHook.isLoadingRecentResponses && !recentResponses.length) && <div className="flex max-h-80 flex-grow p-6">
                  <EmptyState src={sadBox} description="We’ll let you know as soon as there’s an update" />
                </div>}
              {Boolean(!dashboardHook.isLoadingRecentResponses && recentResponses.length) && <div className="flex flex-col">
                  {recentResponses.map((item, i) => {
                return <div role="button" aria-hidden="true" onClick={() => dashboardHook.handleViewRecentResponse(item)} className="flex items-center border-b p-4 md:px-6" key={JSON.stringify(i)}>
                        <div className="">
                          <AvatarPrimary src={item.talentAvatar || userImage} size={58} hideIndicators />
                        </div>
                        <div className="ml-4 flex flex-col text-gray-600">
                          <p className="text-xs font-medium text-black">{item.jobTitle}</p>
                          <span className="mt-2 text-xs">{item.talentFullName}</span>
                          <span className="mt-1 text-xs">Match Score</span>

                          <div className="mt-3 flex items-center text-xs">
                            <div className="h-2 w-16 basis-[70%] rounded-md bg-gray-100">
                              <span
                        // eslint-disable-next-line react/forbid-dom-props
                        style={{
                          width: `${item.matchScore || '0'}%`
                        }} className="block h-2 rounded-md bg-green-600" />
                            </div>
                            <span className="ml-2 basis-[30%]">{`${item.matchScore || '0'}%`}</span>
                          </div>
                        </div>
                      </div>;
              })}
                </div>}
            </div>
          </div>
        </div>
      </div>
      {dashboardHook.setShowMeetingDetailsModal && dashboardHook?.upcomingInterview?.meeting && <MeetingDetailsModal meetingDetails={dashboardHook.upcomingInterview.meeting} showModal={dashboardHook.showMeetingDetailsModal} onCloseDialog={() => dashboardHook.setShowMeetingDetailsModal(false)} handleRefreshUpcomingInterviews={dashboardHook.refreshUpcomingInterview} />}
      {dashboardHook.showCandidateViewModal && dashboardHook.currentCandidateView && <CandidateViewModal showModal={dashboardHook.showCandidateViewModal} tabChoice={{
      isAppliedTab: true,
      isShortlistedTab: false,
      isInterviewsTab: false,
      isHiredTab: false,
      isOffersTab: false,
      isAssessmentTab: false
    }} onCloseDialog={() => dashboardHook.setShowCandidateViewModal(false)} candidateData={dashboardHook.currentCandidateView} />}
    </>;
}