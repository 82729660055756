import Router from '@/utils/windowRouter';
import { getUserTimezone } from './date.helper';
import { getAsPath } from '@/utils/getAsPath';

export default async function Get(
  url: string,
  acceptType?: string,
  token?: string,
  headers?: RequestInit['headers']
) {
  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: acceptType || 'application/json',
        'X-Timezone': getUserTimezone(),
        Authorization: `Bearer ${token || ''}`,
        ...headers,
      },
    });

    if ([401].some((code) => code === response.status)) {
      localStorage.setItem('authRedirect', getAsPath());

      return Router.replace('/auth/logout');
    }
  } catch (error) {
    return { errors: [], message: 'Hmmm...could not fetch!', error };
  }

  if (response && response.ok) return response.json();

  return { errors: [], message: 'Hmmm...could not fetch!', status: 'error' };
}
