import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { MetaDataSchema } from '@/types';

export const LanguagesSchema = z.object({
  data: z.array(z.string()),
  meta: MetaDataSchema,
});

export type Languages = z.infer<typeof LanguagesSchema>;

export async function fetchLanguages() {
  const response = await fetch(`${apiSharedUrl}/lists/languages`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = LanguagesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useLanguagesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'languages'],
    queryFn: fetchLanguages,
    staleTime: 1000 * 60 * 60 * 20, // 20 minutes
  });

  return {
    ...queryFns,
    languages: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
