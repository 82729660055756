// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { apiSharedUrl } from '@/api.config';
import { LabelValuesSchema } from '@/types';

export async function fetchTimezones() {
  const response = await fetch(`${apiSharedUrl}/lists/timezones?new`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = LabelValuesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useTimezonesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'timezones'],
    queryFn: fetchTimezones,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });

  return {
    ...queryFns,
    timezones: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
