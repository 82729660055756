import cn from '@/utils/cn';
import classNames from 'classnames';
import Image, { ImageProps } from 'next/legacy/image';
import React from 'react';
import FilesAdd from '@/public/assets/images/invoices/files-add.svg';
import { titleCase } from '@/utils/string.helper';
type EmptyStateProps = Pick<ImageProps, 'src' | 'alt'> & {
  title?: string;
  description: string;
  className?: string;
  imageSize?: number;
  descClassName?: string;
};
export default function EmptyState({
  title,
  description,
  src,
  alt,
  imageSize,
  className,
  descClassName
}: EmptyStateProps) {
  return <div className={classNames('m-auto flex max-h-80 w-full flex-col items-center justify-center', className)} data-sentry-component="EmptyState" data-sentry-source-file="EmptyState.tsx">
      <div className="mx-auto flex items-center justify-center">
        <Image src={src || FilesAdd} alt={alt || 'Content Empty'} priority height={imageSize} width={imageSize} data-sentry-element="Image" data-sentry-source-file="EmptyState.tsx" />
      </div>
      <div className="text-center">
        {title && <h3 className="text-gray-black mt-3 text-base font-medium leading-6">
            {titleCase(title)}
          </h3>}
        <div className="mt-2">
          <p className={cn('max-w-md text-sm text-gray-500', descClassName)}>{description}</p>
        </div>
      </div>
    </div>;
}