// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { MetaDataSchema } from '@/types';

export const Currencies = z.object({
  currency: z.string(),
  symbol: z.string(),
});

export type CurrenciesType = z.infer<typeof Currencies>;

export const CurrenciesSchema = z.object({
  data: z.array(Currencies),
  meta: MetaDataSchema,
});

export type Countries = z.infer<typeof CurrenciesSchema>;

export async function fetchCurrencies() {
  const response = await fetch(`${apiSharedUrl}/lists/currencies`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = CurrenciesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useCurrenciesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'currencies'],
    queryFn: fetchCurrencies,
    staleTime: 1000 * 60 * 20,
  });

  return {
    ...queryFns,
    currencies: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
