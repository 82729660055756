// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { ArrayOfStringSchema, MetaDataSchema } from '@/types';

export const JobApplicationTagsSchema = z.object({
  data: ArrayOfStringSchema,
  meta: MetaDataSchema,
});

export type JobApplicationTags = z.infer<typeof JobApplicationTagsSchema>;

export async function fetchJobApplicationTags() {
  const response = await fetch(`${apiSharedUrl}/lists/tags/job-applications`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = JobApplicationTagsSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useJobApplicationTagsQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'job-application-tags'],
    queryFn: fetchJobApplicationTags,
    staleTime: 1000 * 60 * 60, // 1 hour
  });

  return {
    ...queryFns,
    jobApplicationTags: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
