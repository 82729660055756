import { capitalCase, sentenceCase } from 'change-case';

const tensNumber = (num?: number) => {
  if (!num) {
    return '00';
  }
  return num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

const getObjectInString = (a: string) => {
  const formattedString = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
  const jsonString = formattedString?.replace(/'/g, '"').replace(/(\w+):/g, '"$1":');
  return JSON.parse(jsonString) || {};
};

const getValueId = (
  labels: string | string[],
  dataArr: any[],
  format: 'string' | 'number' | 'string[]' | 'number[]'
) => {
  // pure string
  if (labels && typeof labels === 'string') {
    const id = dataArr.find((d) => d?.name?.toLowerCase() === labels.toLowerCase())?.id || labels;
    if (format === 'string') {
      return id?.toString();
    }
    return Number(id) || id;
  }

  // array
  if (Array.isArray(labels)) {
    const ids = labels.map((l) => {
      const id = dataArr.find((d) => d?.name.toLowerCase() === l.toLowerCase())?.id || l;
      if (format === 'string') {
        return id?.toString();
      }
      return Number(id) || id;
    });

    return ids;
  }

  return labels;
};

export const sentence = (str = '', delimiter?: string) => {
  return sentenceCase(str, { delimiter: delimiter || ' ', suffixCharacters: '!.' });
};

export const titleCase = (str = '') => {
  return str
    .split(' ')
    .map((w) => {
      if (
        [
          'in',
          'of',
          'on',
          'and',
          'for',
          'by',
          'at',
          'or',
          'to',
          'nor',
          'the',
          'not',
          'a',
          'an',
        ].includes(w)
      ) {
        return w.toLowerCase();
      }
      return capitalize(w);
    })
    .join(' ');
};

export const capitalize = (str = '') => {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
};

export const capitalizer = (str = '', delimiter?: string) => {
  return capitalCase(str, { delimiter: delimiter || ' ' });
};

export const firstLetter = (str = '', bool = true) => {
  return bool ? `${str.slice(0, 1)}` : `${str.slice(0)}`;
};

export default { tensNumber, getObjectInString, getValueId, firstLetter };

// const stringified = JSON.stringify(formattedString);
