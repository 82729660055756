// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { LabelValueSchema, MetaDataSchema } from '@/types';

export const DegreesSchema = z.object({
  data: z.array(LabelValueSchema),
  meta: MetaDataSchema,
});

export type Degrees = z.infer<typeof DegreesSchema>;

export async function fetchDegrees() {
  const response = await fetch(`${apiSharedUrl}/lists/degrees`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();

  const result = DegreesSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useDegreesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'degrees'],
    queryFn: fetchDegrees,
    staleTime: 1000 * 60 * 20, // 20 minutes
  });

  return {
    ...queryFns,
    degrees: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
