// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { apiSharedUrl } from '@/api.config';
import { LabelValueSchema, MetaDataSchema } from '@/types';

export const WorkplaceTypeSchema = z.object({
  data: z.array(LabelValueSchema),
  meta: MetaDataSchema,
});

export type WorkplaceType = z.infer<typeof WorkplaceTypeSchema>;

export async function fetchWorkplaceTypes() {
  const response = await fetch(`${apiSharedUrl}/lists/workplace-types`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const data = await response.json();
  const result = WorkplaceTypeSchema.safeParse(data);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return result.data;
}

export const useWorkplaceTypesQuery = () => {
  const queryFns = useQuery({
    queryKey: ['list', 'workplace-types'],
    queryFn: fetchWorkplaceTypes,
    staleTime: 1000 * 60 * 60 * 20, // 20 minutes
  });

  return {
    ...queryFns,
    workplaceTypes: queryFns?.data?.data || [],
    meta: queryFns?.data?.meta,
  };
};
